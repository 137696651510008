@import "../../assets/css/colors.scss";
@import "../../assets/css/functions.scss";



.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}

.slider {
  background-color: $custom-006;

  .banner-text {
    padding: size(90) 0;
    text-align: left;
  }
}