.preloader {
  display: flex;
  justify-content: center;
}
.spinner {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #385BD3;
  border-radius: 50%;
  position: fixed;
  margin-top: 10px;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
