@import "../../assets/css/colors.scss";
@import "../../assets/css/functions.scss";

.custom-card {
  margin-bottom: 20px;
}

.tab-contents {
  padding: 20px !important;
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-radius: 5px;
  select {
    margin-bottom: 10px;
  }
}

.map-container {
  min-height: size(400);
}

.search-form {
  background-color: $custom-006;
  border-top: 1px solid $border-gray;
  margin-bottom: size(20);
  .card {
    background-color: $custom-006;
  }
}

.main-container {
  width: 95%;
  margin: 0 auto;
}

.photo-grid {
  padding-left: size(20);
  img {
    width: 100%;
  }
}

.quotations {
  .active {
    color: $white;
    background-color: $custom-004;
    border: 1px solid $custom-004;
  }

  .list-group {
    margin-bottom: size(20);
  }
}

.date-upload {
  display: flex;
}

.summary-wrapper {
  padding-right: size(20);
  .stats {
    display: flex;
    .stat-card {
      flex: 1;
      position: relative;
      .stat-card-details{
        border: 1px solid #dee2e6;
        margin-left: size(20);
        text-align: center;
        background-color: $custom-004;
        color: $white;
        border-radius: size(5);
        padding-top: size(10);
        padding-bottom: size(10);
        .icon {
          position: absolute;
          display: block;
          left: size(40);
          width: size(40);
        }
      }
    }
  }
}

.edit-content {
  text-align: right;
}

.content-list {
  padding: size(20);

  th {
    font-weight: bolder;
  }
}

.pagination-wrapper {
  text-align: right;

  .label {
    display: inline-block;
    margin: 20px 0;
  }

  .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 20px 0;
    border-radius: 4px;
  }

  .pagination>li {
    display: inline;
  }

  .pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: #337ab7;
    border-color: #337ab7;
  }

  .pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #337ab7;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
  }
}
