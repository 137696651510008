@import "../../assets/css/colors.scss";
@import "../../assets/css/functions.scss";

.login {
  max-width: size(400);
  padding: size(0);

  .otp {
    margin-top: size(40);
    margin-bottom: size(40);
    text-align: center;
    
    input {
      width: size(40) !important;
      height: size(40);
      border-radius: 80%;
      color: #000000 !important;
      padding: 0px;
    }
    max-width: size(220);
  }

  .btn {
    display: block;
    margin-top: size(20);
  }

  .btn-green {
    background-color: $custom-004;
    color: $white;
    width: 50%;
    margin: 0 auto;
  }

  .phone-number {
    margin-top: size(40);
    margin-bottom: size(40);
  }
}