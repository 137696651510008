@import "../../../assets/css/functions.scss";
.myModal {
  position: absolute;
  top: size(50);
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  z-index: 200;
  max-width: 800px;
  min-height: 180px;
  width: 100vw;
  min-width: 320px;
  box-sizing: border-box;
  color: #4f4f4f;
  outline: none;
  transition: transform 0.2s linear, opacity 0.2s linear;

  &#request-details-modal {
    max-width: 710px;
  }

  &.invisible {
    transform: scale(0.9);
    visibility: visible;
    opacity: 0;
    pointer-events: none;
  }

  &.visible {
    transform: scale(1);
  }

  .modal-title-bar {
    position: relative;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 72px;
    box-sizing: border-box;
    padding: 0 30px;
    font-size: 21px;
    background-color: #f8f8f8;

    .modal-close {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 17px;
      width: 17px;
      outline: none;
      border-style: none;
      background: none;
      margin-right: 5px !important;
    }
  }

  .modal-content {
    width: 100%;
    padding: 15px 15px;
    box-sizing: border-box;
    &#request-details-modal-content {
      padding-right: 80px;
      @media (max-width: 640px) {
        padding-right: 30px;
      }
    }
  }
  .modal-title-id {
    margin: 5px 15px 5px 5px;
  }
  .modal-title-text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    font-family: "DIN Pro Medium";
  }

  .modal-bar {
    position: relative;
    top: 3px;
    border: 1px solid #e4e4e4;
    border-radius: 18px;
    box-sizing: border-box;
    margin-left: 19.5px;
  }
}
@media (max-width: 640px) {
  .modal-bar {
    margin: 0 0 10px 25px;
  }
  .modal-title-text {
    font-size: 15px;
  }
  .modal-title-id {
    margin: 0 0 0 10px;
  }
}

@media (max-width: 568px) {
  .modal {
    width: 90vw;
  }
  .modal-bar {
    margin: 0 0 0 30px;
  }
  .modal-title-text {
    font-size: 14px;
    margin: 0 0 2px 0;
  }
  .modal-title-id {
    margin: 0 0 0 20px;
  }
}

@media (min-width: 640px) {
  .modal {
    top: 50px;
    margin-bottom: 50px;
  }
}

@media (min-width: 1432px) {
  .modal {
    width: 1200px;
  }
}

@media (max-width: 562px) {
  .modal {
    width: 80%;
  }
}

@media (max-width: 568px) {
  .guesthouse-details-wrapper,
  .guesthouse-modal {
    .modal {
      width: 100%;
    }
  }
}

.modal-content {
  padding: 10px;
}
