.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  transition: all .2s ease-out;
  transform-origin: center center;

  &.invisible {
    visibility: visible !important;
    pointer-events: none;
    border: none;
    box-shadow: none;
    background: rgba(0, 0, 0, 0);
  }

  &.visible {
    background: rgba(0,0,0,.72);
    border: none;
 }

  .visible {
    transform: scale(1);
  }
}

.custom-overlay{
  background: rgba(0,0,0,.22);
}

