.custom-card {
  margin-bottom: 20px;
}

.bg-dark {
  .card-title {
    color: white;
  }

  .card-text {
    color: white;
  }
}
