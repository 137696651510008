$white: #FFFFFF;
$custom-001: #2b3036;
$custom-002: #2B3036;
$custom-003: #f2f4f6;
$custom-004: #006734;
$custom-005: #d6dce4;
$border-gray: #ced4da;
$custom-006: #7b0053;
$custom-007: #7c4fe0;
$custom-008: #333333;
$custom-009: #f68123;
$custom-010: #bd1d7b;
$custom-011: #104c5b;
$custom-012: #67a444;