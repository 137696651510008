@import "../../assets/css/colors.scss";
@import "../../assets/css/functions.scss";

.App {
  text-align: center;
}

#root {
  color: $custom-001;
  font-size: size(16);

  a {
    text-decoration: none;
  }
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: $white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.container-fluid {
  /*padding: 0px;*/
}

.bg-blue {
  background-color: $custom-006;

  a {
    color: $white;
  }
}

.bg-blue a {
  color: #ffffff;
  text-decoration: none;
}

.bg-white {
  background-color: $white;

  a {
    color: $custom-001;
  }
}

.text-vision {
  text-decoration: wavy;
  color: #000 !important;
  font-size: size(30);
}

.bg-purple {
  background-color: $custom-006;

  a {
    color: $white !important;
  }
}

.feature-cover {
  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: size(5);
  }

  margin-bottom: size(10);
}

.service-blank {
  color: $white !important;
  position: relative;
  box-shadow: 0 0 40px 0 rgb(0 0 0 / 6%);
  padding: size(20);
  text-align: center;
  border-radius: 6px;
  transition: all 0.3s;
  transform: translateY(0);

  p {
    color: $custom-002 !important;
    margin-bottom: size(0);
    font-size: size(18);
  }

  .strong-5x {
    font-weight: bolder;
    font-size: size(25);
  }
}

.service-custom-1 {
  background-color: $custom-009 !important;

  &:hover {
    background-color: $white !important;
  }
}

.service-custom-2 {
  background-color: $custom-010 !important;

  &:hover {
    background-color: $white !important;
  }
}

.service-custom-3 {
  background-color: $custom-011 !important;

  &:hover {
    background-color: $white !important;
  }
}

.service-custom-4 {
  background-color: $custom-012 !important;

  &:hover {
    background-color: $white !important;
  }
}

.service-purple {
  background-color: $custom-006;
  color: $white !important;
  position: relative;
  box-shadow: 0 0 40px 0 rgb(0 0 0 / 6%);
  padding: size(20);
  text-align: center;
  border-radius: 6px;
  transition: all 0.3s;
  transform: translateY(0);

  p {
    color: $white !important;
    margin-bottom: size(0);
    font-size: size(18);
  }

  .strong-5x {
    font-weight: bolder;
    font-size: size(25);
  }

  &:hover {
    background-color: $white;
    color: $white !important;

    p {
      color: $custom-006 !important;
    }

    .strong-5x {
      font-weight: bolder;
    }
  }
}

.main-area {
  min-height: 500px;
}

.brand-name {
  font-weight: bolder;
  color: #ffffff;
  text-transform: uppercase;
}

.logo {
  height: 30px;
}

.custom-card {
  margin-bottom: 10px;
}

.footer {
  background-color: $custom-002;
  color: $white;
  padding: size(30);

  a {
    color: $white;
  }

  .foot {
    border-top: solid 1px $white;
    padding-top: size(10);
    text-align: center;
  }
}

.carousel-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
}

.carousel-caption {
  top: 15%;
  right: 30%;
  text-align: left;
  z-index: 100;

  h3 {
    font-size: size(45);
    font-weight: bolder;
    margin-bottom: size(15);
  }

  p {
    font-weight: bolder;
  }
}

.customer-review-wrapper {
  padding: size(50) size(150);
  text-align: center;

  .reviews {
    display: flex;
    padding-top: size(10);

    .review {
      border: 1px solid $custom-005;
      border-radius: size(5);
      padding: size(20);
      text-align: left;
      margin-left: size(10);
    }

    .user-info {
      display: flex;
      padding-top: size(20);

      .avatar {
        width: size(70);

        img {
          width: 100%;
        }
      }

      .name-location {
        padding: size(5) size(20);

        .name {
          font-weight: bold;
        }
      }
    }
  }
}

.categories-wrapper {}

.how-it-works-wrapper {
  text-align: center;
  background-color: $custom-003;
  padding: size(50) size(150);

  h2 {
    font-weight: 700;
    font-size: size(36);
  }

  .steps {
    display: flex;
    padding-top: size(50);

    .step {
      flex: 1;

      .icon-wrapper {
        .icon-wrapper-1 {
          position: relative;
          width: size(100);
          height: size(100);
          margin: 0 auto;

          .icon {
            width: 100%;
            height: 100%;
            border: 1px solid $custom-001;
            border-radius: 100%;
            margin: 0 auto;
          }

          .badge {
            position: absolute;
            background: $custom-004;
            color: $white;
            border-radius: 100%;
            padding: size(10);
            bottom: size(0);
            right: size(0);
          }
        }
      }

      .title {
        padding: size(10) 0;
        font-size: size(20);
        font-weight: 700;
      }
    }
  }
}

a.request-now {
  border: 1px solid $custom-004;
  padding: size(7);
  border-radius: size(10);

  &:hover {
    background-color: $custom-004;
    color: $white;
  }
}

.contact-form {
  max-width: size(700);
  margin: 0px auto;

  h1 {
    text-align: center;
    margin: 0 auto;
  }

  small {
    text-align: center;
    margin: size(20) auto;
    display: block;
    font-size: size(16);
  }

  .actions {
    width: 100%;
    text-align: right;
  }

  .phone-number {
    height: size(38);
    border: 1px solid $border-gray;
    border-radius: size(4);

    .code {
      padding: size(5);
    }

    .phone {
      padding: size(5);
    }
  }
}

.phone-number {
  display: flex;
  border: 1px solid $custom-002;
  border-radius: size(7);
  width: 100%;

  .code {
    background-color: $custom-002;
    padding: size(10);
    color: $white;
    width: size(70);
    text-align: center;
  }

  .phone {
    flex: 1;
    padding: size(10);

    input {
      display: block;
      margin: 0 0;
      width: 100%;
      border: none;
      outline: none;
      background: inherit;
    }
  }
}

.tukole-primary {
  background-color: $custom-004;
  color: $white;
  border: none;
  border-radius: size(5);
  padding: size(8) size(12);
}

.custom-row {
  display: flex;

  .custom-col {
    flex: 1;
  }

  .email {
    margin-left: size(15);
  }
}

.main-nav {
  a {
    color: $custom-008;
  }

  a:hover {
    color: $custom-006;
  }

  color: $custom-008;

  .custom-btn {
    padding: 10px 25px;
    border: 1px solid #fff;
    font-weight: 800;
    border-radius: 3px;

    span {
      font-weight: 400;
    }
  }

  .custom-btn:hover {
    border-color: #fff;
    background-color: #fff;
    color: #7c4fe0;
  }

  .nav-link {
    border-right: 1px solid $custom-002;
    line-height: size(19);
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.card {
  border-radius: size(10);
}

.blog-text {
  h4 {
    p {
      font-weight: 700;
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      background: #fff;
    }
  }
}

.blue-strip {
  background-color: $custom-006;
  padding: size(20);
  border-top: 1px solid $border-gray;
  color: $white !important;

  p {
    color: $white;
  }

  a {
    color: $white;
  }

  a:hover {
    color: $white;
  }

  .custom-btn {
    padding: 10px 25px;
    border: 1px solid #fff;
    font-weight: 500;
    border-radius: 3px;
  }
}

button.file-item {
  text-align: left;
  background: none;
  border: none;
  outline: none;
  color: $custom-006;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  :hover {
    text-decoration: underline;
  }
}

button.file-item:focus {
  outline: 0 !important;
}

.content-preview {
  margin: size(20) size(40) 0 size(40);
  min-height: size(300);
}

.download-strip {
  background-color: $custom-006;
  padding: size(10) size(20);

  a {
    color: $white;
  }
}

.red {
  color: red;
}

.sample-slider {
  .blog-slider {
    width: 95%;
    position: relative;
    max-width: 800px;
    margin: auto;
    background: #fff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 25px;
    border-radius: 25px;
    height: 400px;
    transition: all 0.3s;

    @media screen and (max-width: 992px) {
      max-width: 680px;
      height: 400px;
    }

    @media screen and (max-width: 768px) {
      min-height: 500px;
      height: auto;
      margin: 180px auto;
    }

    @media screen and (max-height: 500px) and (min-width: 992px) {
      height: 350px;
    }

    &__item {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      &.swiper-slide-active {
        .blog-slider__img {
          img {
            opacity: 1;
            transition-delay: 0.3s;
          }
        }

        .blog-slider__content {
          >* {
            opacity: 1;
            transform: none;

            @for $i from 0 to 15 {
              &:nth-child(#{$i + 1}) {
                transition-delay: $i * 0.1 + 0.3s;
              }
            }
          }
        }
      }
    }

    &__img {
      // width: 40%;
      width: 300px;
      flex-shrink: 0;
      height: 300px;
      background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
      box-shadow: 4px 13px 30px 1px rgba(252, 56, 56, 0.2);
      border-radius: 20px;
      transform: translateX(-80px);

      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
        border-radius: 20px;
        opacity: 0.8;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        opacity: 0;
        border-radius: 20px;
        transition: all 0.3s;
      }

      @media screen and (max-width: 992px) {
        // width: 45%;
      }

      @media screen and (max-width: 768px) {
        transform: translateY(-50%);
        width: 90%;
      }

      @media screen and (max-width: 576px) {
        width: 95%;
      }

      @media screen and (max-height: 500px) and (min-width: 992px) {
        height: 270px;
      }
    }

    &__content {
      // width: 60%;
      padding-right: 25px;

      @media screen and (max-width: 992px) {
        // width: 55%;
      }

      @media screen and (max-width: 768px) {
        margin-top: -80px;
        text-align: center;
        padding: 0 30px;
      }

      @media screen and (max-width: 576px) {
        padding: 0;
      }

      >* {
        opacity: 0;
        transform: translateY(25px);
        transition: all 0.4s;
      }
    }

    &__code {
      color: #7b7992;
      margin-bottom: 15px;
      display: block;
      font-weight: 500;
    }

    &__title {
      font-size: 24px;
      font-weight: 700;
      color: #0d0925;
      margin-bottom: 20px;
    }

    &__text {
      color: #4e4a67;
      margin-bottom: 30px;
      line-height: 1.5em;
    }

    &__button {
      display: inline-flex;
      background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
      padding: 15px 35px;
      border-radius: 50px;
      color: #fff;
      box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
      text-decoration: none;
      font-weight: 500;
      justify-content: center;
      text-align: center;
      letter-spacing: 1px;

      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }

    .swiper-container-horizontal>.swiper-pagination-bullets,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
      bottom: 10px;
      left: 0;
      width: 100%;
    }

    &__pagination {
      position: absolute;
      z-index: 21;
      right: 20px;
      width: 11px !important;
      text-align: center;
      left: auto !important;
      top: 50%;
      bottom: auto !important;
      transform: translateY(-50%);

      @media screen and (max-width: 768px) {
        transform: translateX(-50%);
        left: 50% !important;
        top: 205px;
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 8px 0;

        @media screen and (max-width: 768px) {
          margin: 0 5px;
        }
      }

      .swiper-pagination-bullet {
        width: 11px;
        height: 11px;
        display: block;
        border-radius: 10px;
        background: #062744;
        opacity: 0.2;
        transition: all 0.3s;

        &-active {
          opacity: 1;
          background: #fd3838;
          height: 30px;
          box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);

          @media screen and (max-width: 768px) {
            height: 11px;
            width: 30px;
          }
        }
      }
    }
  }
}

.content-header {
  font-weight: bolder;
  text-transform: capitalize;
  font-size: size(20);
  margin-bottom: size(20);
  padding-bottom: size(5);
  width: 100%;
  border-bottom: size(1) solid $custom-008;
}

.loader-wrapper {
  width: 30%;
  margin-top: size(100);
  margin-left: auto;
  margin-right: auto;
  border-radius: size(5);
  border: size(1) solid $custom-008;
  height: size(100);
  padding-top: size(30);
  padding-left: size(35);

  .txt {
    font-size: size(25);
  }
}

.content-body {
  width: 90%;
  margin-top: size(10);
  margin-left: auto;
  margin-right: auto;

  img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: size(10);
  }
}

.btn-action-primary {
  color: #FFFFFF !important;
  background-color: $custom-011;
  padding: size(3);
  border-radius: size(5);
}

.a-action-primary {
  color: $custom-011 !important;
  display: block;
  padding-top: size(10);
}

.main-nav {
  .dropdown-item {
    color: $custom-006 !important;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.main-content-area {
  margin-left: size(10);
  margin-right: size(10);
  margin-top: size(70);
}

.team-wrapper {
  margin-bottom: size(30);

  .team-photo {
    width: 100%;
    text-align: center;

    img {
      display: block;
      border: size(1) solid $border-gray;
    }
  }

  .team-body {
    border: 1px solid $custom-006;
    background: $custom-006;
    padding: size(10);
    width: 100%;
    border-radius: size(5);
    color: #FFFFFF;
    margin-top: size(-2);

    .team-name {
      font-weight: bolder;
    }

    .team-title {
      font-size: size(14);
      padding-top: size(3);
      color: $border-gray;
    }

    .team-bio {
      padding-top: size(10);
      font-size: size(15);
    }
  }
}

.slide {
  .legend-custom {
    transition: all .5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 40%;
    border-radius: 10px;
    color: #fff;
    padding: 10px;
    text-align: left;
    opacity: 1;
    font-weight: bolder;
    transition: opacity .35s ease-in-out;
    border-left: size(3) solid $custom-006;
    background-color: rgba(0, 0, 0, 0.5);

    .slider-header {
      font-size: size(50);
      line-height: size(50);
    }

    .slider-caption {
      margin-top: size(10);
      color: $border-gray;
    }
  }
}

#slider {
  .slider-footer {
    background-color: $custom-006;
    color: #FFFFFF;
    padding: size(15);
    padding-left: size(70);
  }
}

.txt-qoute {
  border-left: size(4) solid $custom-006;
  padding-left: size(10);
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: $custom-002 !important;
}

.media-channels {
  margin-bottom: size(40);

  .channel {
    height: size(60);
    border: 1px solid $custom-006;
    border-radius: size(5);
    background-color: $custom-006;
    padding-top: size(10);

    a {
      font-size: size(25);
      color: #FFFFFF;
    }

    text-align: center;
  }

  .twitter {
    background-color: #00acee;
    border: 1px solid #00acee;
  }

  .facebook {
    background-color: #4267B2;
    border: 1px solid #4267B2;
  }
}

.media {
  .blog-home {
    .res-margin {
      margin-bottom: size(20);
    }
  }
}

.ministry-card {
  border-left: size(5) solid $custom-006;
  padding-left: size(10);
  min-height: size(140);
}


.about-header {
  font-weight: bolder;
  line-height: size(40);
  font-size: size(30);
  margin-bottom: size(10);
}

.about-sub-header {
  font-weight: bolder;
  line-height: size(40);
  font-size: size(20);
  margin-bottom: size(10);
  margin-top: size(10);
  text-transform: uppercase;
}

.about-sub-sub-header {
  font-weight: bold;
  color: $custom-011;
  padding-bottom: size(5);
  text-transform: capitalize;
}

#about {
  .vision {
    border-left: size(2) solid $custom-011;
    padding-left: size(10);
    margin-bottom: size(15);
  }
}

.blog-col {
  iframe {
    height: size(200);
  }
}

.blog-wrapper1 {
  .blog-about {
    padding: size(5) size(0);
  }
}